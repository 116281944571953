/* Carousel styles */
.swiper.carousel-swiper {
  padding: 24px 0;
}
.carousel-swiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: white;
  border: 2px solid rgba(52, 67, 114, 1);
  opacity: 1;
}

.carousel-swiper .swiper-pagination-bullet-active {
  background-color: rgba(52, 67, 114, 1);
}

.carousel-swiper .swiper-pagination-bullet:hover {
  background-color: rgba(52, 67, 114, 1);
}

.carousel-swiper .swiper-pagination.swiper-pagination-bullets {
  bottom: -4px;
}

.carousel-swiper .swiper-button-next,
.carousel-swiper .swiper-button-prev {
  color: #344372;
  opacity: 1;
  transition: all 0.3s ease;
}

.carousel-swiper:hover .swiper-button-next,
.carousel-swiper:hover .swiper-button-prev {
  opacity: 1;
}

.carousel-swiper .swiper-button-next:after,
.carousel-swiper .swiper-button-prev:after {
  font-size: 28px;
}

.carousel-swiper .swiper-button-next.swiper-button-disabled,
.carousel-swiper .swiper-button-prev.swiper-button-disabled {
  opacity: 0.25;
}

.carousel-swiper .swiper-button-next {
  right: -40px;
}

.carousel-swiper .swiper-button-prev {
  left: -40px;
}

@media only screen and (max-width: 992px) {

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .carousel-swiper .swiper-pagination.swiper-pagination-bullets {
    bottom: -8px;
  }
}

/* Media gallery styles */
.media-gallery-swiper .swiper-button-next,
.media-gallery-swiper .swiper-button-prev {
  color: #242f50;
  opacity: 0;
  transition: all 0.3s ease;
}

.media-gallery-swiper .swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}

.media-gallery-swiper .swiper-pagination-bullet-active {
  background-color: #62abea;
}

.media-gallery-swiper .swiper-button-next,
.media-gallery-swiper .swiper-button-prev {
  color: #242f50;
  opacity: 0;
  transition: all 0.3s ease;
}

.media-gallery-swiper:hover .swiper-button-next,
.media-gallery-swiper:hover .swiper-button-prev {
  opacity: 1;
}

.media-gallery-swiper .swiper-button-next:after,
.media-gallery-swiper .swiper-button-prev:after {
  font-size: 28px;
}

.media-gallery-swiper .swiper-button-next.swiper-button-disabled,
.media-gallery-swiper .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

@media only screen and (max-width: 992px) {

  .media-gallery-swiper .swiper-button-next,
  .media-gallery-swiper .swiper-button-prev {
    opacity: 0 !important;
  }
}

.min-h-screen {
  min-height: calc(100vh);
  /* For browsers that don't support CSS variables */
  min-height: calc((var(--1svh, 1vh) * 100));
  /* This is the "polyfill" */
  min-height: calc(100svh);
}

@media only screen and (max-width: 768px) {
  .min-safe-h-screen-video {
    height: calc(100vh - 48px);
    /* For browsers that don't support CSS variables */
    height: calc((var(--1svh, 1vh) * 100) - 48px);
    /* This is the "polyfill" */
    height: calc(100svh - 48px);
  }
}

@media only screen and (min-width: 768px) {
  .min-safe-h-screen-video {
    height: calc(100vh - 56px);
    /* For browsers that don't support CSS variables */
    height: calc((var(--1svh, 1vh) * 100) - 56px);
    /* This is the "polyfill" */
    height: calc(100svh - 56px);
  }
}

@media only screen and (min-width: 992px) {
  .min-safe-h-screen-video {
    height: calc(100vh - 64px);
    /* For browsers that don't support CSS variables */
    height: calc((var(--1svh, 1vh) * 100) - 64px);
    /* This is the "polyfill" */
    height: calc(100svh - 64px);
  }

  .media-gallery-swiper .swiper-button-next {
    right: 40px;
  }

  .media-gallery-swiper .swiper-button-prev {
    left: 40px;
  }

  .media-gallery-swiper .swiper-button-next:before,
  .media-gallery-swiper .swiper-button-prev:before {
    z-index: -1;
    border-radius: 50%;
    content: "";
    position: absolute;
    width: 64px;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.no-pan-y {
  overscroll-behavior-y: none;
  touch-action: pan-y;
}

/* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */
@media only screen and (min-width: 768px) {
  .scrollbar {
    overflow: overlay;
  }

  /* total width */
  .scrollbar::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    width: 10px;
    height: 10px;
    z-index: 999999;
  }

  /* background of the scrollbar except button or resizer */
  .scrollbar::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  /* scrollbar itself */
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    border: 0px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  .scrollbar::-webkit-scrollbar-button {
    display: none;
  }

  /* scrollbar when element is hovered */
  .scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: #6f768b;
    border: 4px solid rgba(0, 0, 0, 0);
  }

  /* scrollbar when scrollbar is hovered */
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #6f768b;
    border: 4px solid #f4f4f400;
  }

  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #6f768b #ffffff;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.video-player {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.youtube-iframe {
  width: 100%;
  height: 100%;
}

.popover {
  transform-origin: top left;
  transform: scale(0);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 1px -1px 2px rgba(52, 67, 114, 0.08),
    2px 0px 4px rgba(52, 67, 114, 0.2);
}

.popover.opened {
  opacity: 1;
  transform: scale(1);
}

.filter-accordion-expanded+div.chakra-collapse {
  overflow: initial !important;
}